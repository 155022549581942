.notifications {
    width: 100%;
    background-color: #ffffff;
    margin-top: 12px;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    position: relative;
    min-height: 440px;
}

.notifications-inner {
    width: 100%;
}

.notification {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 12px 20px;
    box-sizing: border-box;
    border-bottom: solid #e7e7e7;
}

.notification-image {
    width: 45px;
    height: 45px;
    border-radius: 45px;
    display: block;
}

.notification-right {
    margin-left: 15px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.notification-btns {
    height: 100%;
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.notification-btns .btn {
    cursor: pointer;
}

.notification-btns .btn-decline {
    color: red;
}

.notification-name {
    display: block;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    font-size: 17px;
    text-decoration: none;
    color: #000000;
    cursor: pointer;
}

.notification-text {
    display: block;
    margin-top: 3px;
    font-size: 16px;
}

.notifications-nav {
    display: flex;
    flex-direction: row;
}

.notifications-nav-element {
    width: 16.66%;
    display: flex;
    padding-top: 8px;
    padding-bottom: 8px;
    justify-content: center;
    align-items: center;
    border-bottom: solid #e7e7e7;
    border-right: solid #e7e7e7;
    cursor: pointer;
    position: relative;
}

.notifications-nav-element-badge {
    width: 24px;
    position: absolute;
    left: 22px;
    top: -12px;
    height: 24px;
    border-radius: 24px;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    color: #FFFFFF;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
}

.notifications-nav-element.active {
    background-color: rgba(0, 0, 0, .1)
}

.notifications-nav-element:hover {
    opacity: 4;
}

.notifications-nav-element-icon {
    display: block;
    height: 40px;
}

.notifications-nav-element-text {
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    font-size: 20px;
}

@media only screen and (max-width: 790px) {

    .notifications-nav-element-icon {
        height: 20px;
    }

}