.user-page {
    width: 100%;
    background-color: #ffffff;
    margin-top: 11px;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
    min-height: 440px;
}

.user-page-left {
    position: relative;
    width: 40%;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
}

.user-page-hey {
    position: absolute;
    top: 6px;
    right: 36px;
    z-index: 20;
    height: 30px;
    display: block;
}

.user-page-right {
    width: 60%;
    padding-bottom: 30px;
    padding-right: 30px;
    padding-left: 30px;
    box-sizing: border-box;
}


.user-page-action-icons {
    display: flex;
    flex-direction: row;
}

.user-page-action-icon {
    height: 40px;
    width: 40px;
    display: flex;
    border: 1px solid #e7e7e7;
    border-radius: 4px;
    margin-right: 12px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.user-page-action-icon.active {
    background-color: #133FAF;
}

.user-page-action-icon:hover {
    opacity: .7;
}

.user-page-action-icon-image {
    height: 17px;
    display: block;
}

.user-page-photo {
    width: 100%;
    border-radius: 10px 10px 0 0;
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 276px;
    background-color: #A1A1A1;
}

.user-page-private-photos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 18px;
}

.user-page-private-photo {
    width: 20%;
    margin-right: 4%;
    cursor: pointer;
    margin-top: 4%;
    border-radius: 10px;
    display: flex;
    background-color: #E3E3E6;
    align-items: center;
    justify-content: center;
}

.user-page-private-photo-image {
    border-radius: 10px;
    width: 100%;
    height: 100%;
}

.user-page-private-photo:last-child {
    margin-right: 0;
}

.user-page-private-photo-icon {
    width: 20px;
    height: 20px;
}

.user-page-block-title {
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    color: #000;
    font-size: 20px;
    display: block;
    margin-top: 30px;
}

.user-page-block-tips {
    flex-wrap: wrap;
    display: flex;
    margin-top: 10px;
}

.user-page-block-tip {
    margin-right: 10px;
    padding: 8px 12px;
    background-color: #e3e3e6;
    color: #000000;
    margin-top: 10px;
    border-radius: 14px;
    font-size: 14px;
}

.user-page-share {
    display: flex;
    flex-direction: row;
    height: 18px;
    margin-top: 10px;
    align-items: center;
}

.user-page-share.clickable {
    cursor: pointer;
}

.user-page-share.clickable:hover {
    opacity: .7;
}


.user-page-share-text {
    font-size: 14px;
}

.user-page-share-icon {
    height: 18px;
    display: block;
    margin-right: 4px;
}

.user-page-block-text {
    margin-top: 10px;
    color: #000;
    font-size: 14px;
    display: block;
}

.user-page-bottom {
    width: 100%;
    position: relative;
    background-color: rgba(0, 0, 0.2);
    padding: 10px 15px 10px 25px;
    box-sizing: border-box;
    border-radius: 0 0 16px 16px;
}

.user-page-name {
    color: #ffffff;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    height: 25px;
    line-height: 25px;
    display: block;
    font-size: 18px;
    font-family: 'Source Sans Pro Bold', sans-serif;
}

.user-page-location {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2px;
}

.user-page-location-icon {
    height: 14px;
    display: block;
}

.user-page-location-text {
    font-size: 14px;
    display: block;
    margin-left: 6px;
    color: #ffffff;
    font-family: 'Source Sans Pro Light', sans-serif;
}

.user-page-location-circle {
    position: absolute;
    left: 12px;
    top: 8px;
    width: 10px;
    height: 10px;
}

.share-modal-text {
    display: block;
    font-size: 18px;
    color: #5C5C5C;
    margin-top: 10px;
}

.share-modal-elements {
    margin-top: 40px;
}

.share-modal-element {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.share-modal-element-image {
    border-radius: 40px;
    width: 40px;
    height: 40px;
}

.share-modal-element-right {
    margin-left: auto;
}

.share-modal-element-type {
    margin-left: 20px;
    font-size: 18px;
    color: #000;
    text-transform: capitalize;
}

.note-modal {}

.note-modal-textarea {
    resize: none;
    width: 100%;
    font-size: 15px;
    height: 175px;
    box-sizing: border-box;
    padding: 15px;
    border-radius: 10px;
    background-color: #EEEEEE;
    color: #000;
}

.note-modal-bottom {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    box-sizing: border-box;
}

.note-modal-submit-button {
    background-color: #005BFA;
    border: 1px solid #005BFA;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    padding: 13px 17px;
    border-radius: 10px;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    cursor: pointer;
}

.note-modal-submit-button:hover {
    opacity: .7;
}

@media only screen and (max-width: 890px) {

    .user-page-left,
    .user-page-right {
        width: 100%;
    }

    .user-page-right {
        margin-top: 30px;
    }

    .user-page {
        flex-direction: column;
    }

}