.visibility {
    width: 100%;
    background-color: #ffffff;
    margin-top: 12px;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    position: relative;
    min-height: 500px;
    box-sizing: border-box;
}

.visibility-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.visibility-left {
    width: 50%;
    box-sizing: border-box;
    border-right: 1px solid #e7e7e7;
}

.visibility-right {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    flex-direction: column;
}

.visibility-element {
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    flex-direction: row;
    padding: 12px;
    box-sizing: border-box;
    align-items: center;
}

.visibility-element-right {
    margin-left: auto;
}

.visibility-element-label {
    color: #000;
    font-size: 17px;
}

.visibility-tab-top {
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    flex-direction: row;
    padding: 12px;
    box-sizing: border-box;
    cursor: pointer;
    align-items: center;
}

.visibility-tab-top.active {
    font-family: 'Source Sans Pro SemiBold', sans-serif;
}

.visibility-tab-top-icon {
    margin-left: auto;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    font-size: 20px;
}

.visibility-tab-content {
    padding: 12px;
    box-sizing: border-box;
}

.visibility-map-bottom-text {
    display: block;
    text-align: center;
    margin-top: 8px;
    font-size: 13px;
    color: #000;
}

.visibility-icon {
    max-width: 50%;
    display: block;
}

.visibility-tab-content-element {
    display: flex;
    flex-direction: row;
    height: 50px;
    align-items: center;
}

.visibility-tab-content-element-right {
    margin-left: auto;
}

.visibility-tab-content-element-input {
    background-color: #EEEEEE;
    border: 1px solid rgba(118, 118, 128, .13);
    padding-left: 10px;
    padding-right: 10px;
    width: 60px;
    text-align: center;
    border-radius: 10px;
    height: 30px;
    color: #000;
    font-size: 17px;
    font-family: 'Source Sans Pro Regular', sans-serif;
    outline: rgba(118, 118, 128, .13);
}

.visibility-element-icon  {
    margin-left: 10px;
    width: 25px;
    height: 25px;
    display: block;
    cursor: pointer;
    position: relative;
    outline: none;
    bottom: 2px;
}

.visibility-tab-top-info {
    width: 25px;
    height: 25px;
    display: block;
    cursor: pointer;
    top: 10px;
    position: absolute;
    left: 120px;
    outline: none;
    bottom: 2px;
    z-index: 20;
}

.visibility-tab-top-info:hover, .visibility-element-icon:hover {
    opacity: .7;
}

.visibility-tab {
    position: relative;
}

.visibility-right-text {
    display: block;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
    text-align: center;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
}

@media only screen and (max-width: 790px) {

    .visibility {
        min-height: auto;
    }

    .visibility-row {
        flex-direction: column-reverse;
    }

    .visibility-left {
        border-left: 0;
        width: 100%;
    }

    .visibility-right {
        width: 100%;
        border-bottom: 1px solid #e7e7e7;
    }

    .visibility-right-text {
        font-size: 16px;
        margin-bottom: 15px;
    }


}
