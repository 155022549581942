.header {
    height: 86px;
    width: 100%;
    background-color: #ffffff;
}

.header-logo {
    height: 46px;
    display: block;
    margin-top: 20px;
}

.header-logo img {
    height: 46px;
    display: block;
}

.header-right {
    margin-left: auto;
    display: flex;
    height: 86px;
    align-items: center;
}

.header-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.header-menu li {
    margin-left: 40px;
    justify-content: center;
    text-decoration: none;
}

.header-menu li a {
    font-size: 14px;
    font-weight: 600;
    color: #7A7A7A;
    text-transform: uppercase;
    text-decoration: none;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
}

.header-menu li a.active, .header-menu a:hover {
    color: #CE181E;
}

.header-apps {
    display: flex;
    flex-direction: row;
    margin-left: 60px;
}

.header-apps-link {
    height: 43px;
    display: block;
    margin-left: 20px;
}

.header-apps-link:first-child {
    margin-left: 0;
}

.header-apps-link img {
    height: 43px;
    display: block;
}

@media only screen and (max-width: 890px) {

    .header-menu {
        display: none;
    }

}

.header-mobile-right {
    display: none;
}

@media only screen and (max-width: 790px) {

    body {
        padding-top: 50px;
    }

    .header {
        height: 50px;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 80;
    }

    .header-mobile-right {
        display: block;
    }

    .header-logo {
        height: 26px;
    }

    .header-logo img {
        height: 26px;
    }

    .header-logo {
        margin-top: 12px;
    }

    .header-right {
        display: none;
    }

    .header-mobile-right {
        display: flex;
        flex-direction: row;
        margin-left: auto;
    }

    .header-mobile-apps {
        display: flex;
        margin-top: 5px;
        margin-right: 20px;
        flex-direction: row;
    }

    .header-mobile-apps-link img {
        height: 40px;
        display: block;
        margin-right: 15px;
    }

    .header-mobile-apps-link {
        height: 40px;
        display: block;
    }
}
