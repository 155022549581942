.users {
    width: calc(100% + 10px);
    margin-left: -10px;
}

.users-loader {
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.users:after {
    clear: both;
    display: block;
    content: '';
}

.user {
    width: calc(25% - 10px);
    float: left;
    margin-left: 10px;
    margin-top: 10px;
    box-sizing: border-box;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
}

.user-inner {
    padding-top: 100%;
    height: auto;
    position: relative;
    border-radius: 16px;
}

.user-hey {
    position: absolute;
    right: 8px;
    top: 8px;
    box-shadow: 0 0 6px rgba(0, 0, 0, .4);
    width: 30px;
    height: 30px;
    border-radius: 4px;
    z-index: 20;
}

.user-visited {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    z-index: 20;
}

.user-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background-repeat: no-repeat;
    background-size: cover;
}

.user-bottom .user-location-circle {
    position: absolute;
    left: 12px;
    top: 8px;
    width: 10px;
    height: 10px;
}

.user-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 10px 15px 10px 25px;
    box-sizing: border-box;
    border-radius: 0 0 16px 16px;
}

.user-name {
    color: #ffffff;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    height: 25px;
    line-height: 25px;
    display: block;
    font-size: 18px;
    font-family: 'Source Sans Pro Bold', sans-serif;
}

.user-dop {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2px;
}

.user-dop-icons {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.user-dop-icon {
    width: 20px;
    height: 20px;
    margin-left: 4px;
}

.user-location {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.user-location-icon {
    height: 14px;
    display: block;
}

.user-location-text {
    font-size: 14px;
    display: block;
    margin-left: 6px;
    color: #ffffff;
    font-family: 'Source Sans Pro Light', sans-serif;
}

.users-empty {
    margin-top: 30px;
}

.users-empty-text {
    display: block;
    color: #FFFFFF;
    font-size: 18px;
    text-align: center;
}

.users-empty-button {
    margin: 30px auto 20px;
    display: block;
    background-color: #133FAF;
    border-radius: 10px;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    cursor: pointer;
    color: #FFFFFF;
    text-decoration: none;
    padding: 10px 12px;
    max-width: 180px;
    font-size: 24px;
    text-align: center;
}

.users-empty-button:hover {
    opacity: .7;
}

@media only screen and (max-width: 990px) {

    .user {
        width: 30%;
    }

    .user-name {
        font-size: 14px;
    }

}

@media only screen and (max-width: 690px) {

    .user {
        width: 47.3%;
    }

    .user-name {
        font-size: 14px;
    }

}