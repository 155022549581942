.settings {
    width: 100%;
    background-color: #ffffff;
    margin-top: 12px;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    position: relative;
    min-height: 500px;
    box-sizing: border-box;
}

.settings-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.settings-left {
    width: 50%;
    box-sizing: border-box;
    border-right: 1px solid #e7e7e7;
}

.settings-right {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.settings-icon {
    max-width: 50%;
    display: block;
}

.settings-element {
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    flex-direction: row;
    padding: 12px;
    box-sizing: border-box;
    align-items: center;
}

.settings-element-right {
    margin-left: auto;
}

.settings-element-label {
    color: #000;
    font-size: 17px;
}

.settings-element-info-icon {
    margin-left: 10px;
    width: 25px;
    height: 25px;
    outline: none;
    display: block;
    cursor: pointer;
    position: relative;
    bottom: 2px;
}

.settings-element-info-icon:hover {
    opacity: .7;
}

.settings-element-link {
    color: #000;
    font-size: 17px;
    text-decoration: none;
    cursor: pointer;
}

.settings-element-link:hover {
    opacity: .7;
}

.settings-element-link.red {
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    color: red;
}

.settings-right-text {
    display: block;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
    text-align: center;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
}

.settings-tab-top-info {
    width: 25px;
    height: 25px;
    display: block;
    cursor: pointer;
    top: 10px;
    position: absolute;
    left: 120px;
    outline: none;
    bottom: 2px;
    z-index: 20;
}

.settings-tab {
    position: relative;
}

.settings-tab-top-label {
    color: #000;
    font-size: 17px;
}

.settings-tab-top {
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    flex-direction: row;
    padding: 12px;
    box-sizing: border-box;
    cursor: pointer;
    align-items: center;
}

.settings-tab-top.active {
    font-family: 'Source Sans Pro SemiBold', sans-serif;
}

.settings-tab-top-icon {
    margin-left: auto;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    font-size: 20px;
}

.settings-tab-content {
    padding: 12px;
    box-sizing: border-box;
}

.general-info-element {
    margin-top: 10px;
}

.general-info-element-top {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.general-info-element-change {
    display: block;
    text-align: right;
    margin-top: 3px;
    color: #002b7d;
    font-size: 12px;
    cursor: pointer;
    outline: none;
}
.general-info-element-change:hover {
    opacity: .7;
}

.general-info-element-label {
    font-size: 14px;
}

.general-info-element-input {
    height: 30px;
    width: calc(100% - 120px);
    border: 1px solid #2C3149;
    border-radius: 4px;
    background-color: #EEEEEE;
    outline-color: rgba(0, 80, 219, 1);
    font-family: 'Source Sans Pro Regular', sans-serif;
    padding-left: 10px;
    font-size: 14px;
    padding-right: 10px;
    box-sizing: border-box;
    margin-left: auto;
}

.change-password-modal-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 16px;
}

.change-password-modal-container .btn {
    color: #000;
    font-size: 17px;
    text-decoration: none;
    cursor: pointer;
}

.change-password-modal-container .cancel {
    color: red;
}

.change-password-modal-container input {
    width: 100%;
    height: 30px;
    border: 1px solid #2C3149;
    border-radius: 4px;
    background-color: #EEEEEE;
    outline-color: rgba(0, 80, 219, 1);
    font-family: 'Source Sans Pro Regular', sans-serif;
    padding-left: 10px;
    font-size: 14px;
    padding-right: 10px;
    box-sizing: border-box;
}

.change-password-modal-container h2 {
    margin: 0;
}

.change-password-modal-container .btns-container {
    display: flex;
    margin: 0 auto;
    gap: 20px;
}

@media only screen and (max-width: 790px) {

    .settings {
        min-height: auto;
    }

    .settings-row {
        flex-direction: column-reverse;
    }

    .settings-left {
        border-left: 0;
        width: 100%;
    }

    .settings-right {
        width: 100%;
        padding-top: 20px;
        border-bottom: 1px solid #e7e7e7;
    }

    .settings-right-text {
        font-size: 16px;
        margin-bottom: 15px;
    }


}
