.footer {
    background-color: #ffffff;
    height: 100px;
}

.footer .container {
    display: flex;
    justify-content: space-between;
}

.footer-left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-right {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.footer-left-logo {
}

.footer-left-logo img {
    height: 50px;
    display: block;
}

.footer-left-copyright {
    display: block;
    color: #7A7A7A;
    font-size: 14px;
    font-family: 'Source Sans Pro Light', sans-serif;
    margin-top: 15px;
}

.footer-left-links {
    display: flex;
    flex-direction: row;
    margin-top: 7px;
    align-items: center;
}

.footer-left-link {
    color: #1e73be;
    font-size: 14px;
    font-family: 'Source Sans Pro Light', sans-serif;
    text-transform: uppercase;
    text-decoration: none;
}

.footer-left-link-line {
    color: #7A7A7A;
    font-size: 10px;
    font-family: 'Source Sans Pro Light', sans-serif;
    margin-left: 7px;
    margin-right: 7px;
}

.footer-apps {
    display: flex;
    flex-direction: row;
    margin-left: 60px;
    margin-top: 15px;
}

.footer-apps-link {
    height: 43px;
    display: block;
    margin-left: 20px;
}

.footer-apps-link:first-child {
    margin-left: 0;
}

.footer-apps-link img {
    height: 43px;
    display: block;
}

@media only screen and (max-width: 790px) {
    .footer {
        height: 60px;
    }

    .footer-left {
        height: 60px;
    }

    .footer-right {
        display: none;
    }

    .footer-left-logo {
        display: none;
    }

    .footer-left-copyright {
        margin-top: 0;
    }

    .footer-left-copyright {
        font-size: 12px;
    }

    .footer-left-link {
        color: #1e73be;
        font-size: 12px;
    }


}
