.edit-profile {
    width: 100%;
    background-color: #ffffff;
    margin-top: 37px;
    border-radius: 7px;
    position: relative;
}

.edit-profile-inner {
    display: flex;
    flex-direction: row;
    position: relative;
}

.edit-profile-loading {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.8);
}

.edit-profile-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    margin-top: 10px;
}

.edit-profile-back {
    color: #005BFA;
    font-size: 18px;
    text-decoration: none;
    display: block;
    margin-right: 40px;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
}

.edit-profile-submit {
    background-color: #005BFA;
    border: 1px solid #005BFA;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 13px 17px;
    border-radius: 10px;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    cursor: pointer;
}

.edit-profile-submit:hover, .edit-profile-back:hover {
    opacity: .7;
}

@keyframes spinner {
    to {transform: rotate(360deg);}
}

.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
    border: 1px solid #ccc;
    border-top-color: #07d;
    animation: spinner .6s linear infinite;
}

.edit-profile-left {
    width: 50%;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
}

.edit-profile-image-area {
    width: 100%;
    border-radius: 10px;
    margin-top: 43px;
    height: 330px;
    position: relative;
    box-sizing: border-box;
    padding-top: 45px;
}

.edit-profile-image-type-icon {
    width: 60px;
    height: 60px;
    position: absolute;
    left: 20px;
    top: 135px;
    border-radius: 60px;
}

.edit-profile-image-type-icon.friend {
    background-color: #1A4FD6;
}

.edit-profile-image-type-icon.flirt {
    background-color: #232D95;
}

.edit-profile-image-type-icon.fun {
    background-color: #202020;
}

.edit-profile-image-type-icon-image {
    width: 60px;
    height: 60px;
    display: block;
}

.edit-profile-image-area-plus {
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 45px;
    right: 80px;
}

.edit-profile-image-area-circle {
    width: 240px;
    margin: auto;
    border: 1px solid #A1A1A1;
    border-radius: 240px;
    height: 240px;
    display: block;
}

.edit-profile-right {
    width: 50%;
    padding-bottom: 30px;
    padding-right: 30px;
    padding-left: 30px;
    box-sizing: border-box;
}


.edit-profile-date {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.edit-profile-date-label {
    display: block;
    font-size: 14px;
    line-height: 18px;
    color: #2D384F;
}

.edit-profile-date-right {
    margin-left: auto;
}

.edit-profile-date input {
    background-color: #EEEEEE;
    border: 1px solid rgba(118, 118, 128, .13);
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    width: 90px;
    border-radius: 10px;
    height: 30px;
    color: #000;
    font-size: 15px;
    font-family: 'Source Sans Pro Regular', sans-serif;
    outline: rgba(118, 118, 128, .13);
}

.edit-profile-hey {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
}

.edit-profile-hey-right {
    margin-left: auto;
    width: 80px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.edit-profile-hey-left {
    width: calc(100% - 80px);
}

.edit-profile-hey-left-text {
    display: block;
    font-size: 14px;
    line-height: 18px;
    color: #2D384F;
    margin-top: 10px;
}

.edit-profile-hey-left-text:first-child {
    margin-top: 0;
}

.edit-profile-large-text {
    margin-top: 10px;
}

.edit-profile-large-text-input {
    resize: none;
    width: 100%;
    font-size: 15px;
    height: 200px;
    box-sizing: border-box;
    padding: 15px;
    border-radius: 10px;
    background-color: #EEEEEE;
    color: #000;
}

.edit-profile-large-text-input::placeholder {
    color: #5C5C5C;
}

.checkbox-label {
    background-color: #20BCFC;
    width: 17px;
    height: 17px;
    border-radius: 17px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkbox-icon {
    width: 17px;
    height: 17px;
    border-radius: 17px;
    margin-left: 10px;
    display: block;
}

.checkbox-label-text {
    font-size: 12px;
    color: #FFFFFF;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
}

.edit-profile-checkboxes {
    flex-wrap: wrap;
    display: flex;
    margin-top: 10px;
}

.edit-profile-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 20px;
    cursor: pointer;
    margin-top: 10px;
    margin-right: 20px;
}

.edit-profile-checkbox-checkmark {
    background-color: #FFFFFF;
    border: 1px solid #2C3149;
    box-sizing: border-box;
    border-radius: 2px;
    width: 17px;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-profile-checkbox-checkmark.rounded {
   border-radius: 100%;
}

.edit-profile-checkbox-checkmark-inner {
    width: 13px;
    height: 13px;
    background-color: #1a4fd6;
    display: none;
}

.edit-profile-checkbox-checkmark-inner.rounded {
    border-radius: 13px;
}

.edit-profile-checkbox-label {
    margin-left: 10px;
    font-size: 14px;
    line-height: 18px;
    color: #2D384F;
    user-select: none;
}

.edit-profile-checkbox input[type=checkbox] {
    width: 0;
    height: 0;
    opacity: 0;
}

.edit-profile-checkbox input[type=checkbox]:checked ~ .edit-profile-checkbox-checkmark .edit-profile-checkbox-checkmark-inner {
    display: block;
}

.edit-profile-block {
    margin-top: 20px;
}

.edit-profile-block:first-child {
    margin-top: 0;
}

.edit-profile-block:first-child {
    margin-top: 0;
}

.edit-profile-block-title {
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    color: #000;
    font-size: 20px;
    display: block;
    margin-top: 40px;
}

.edit-profile-block-sub-title {
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    color: #2C3149;
    font-size: 16px;
    display: block;
}

.edit-profile-private-switcher {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.edit-profile-private-switcher-right {
    margin-left: auto;
    width: 80px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.edit-profile-private-switcher-label {
    width: calc(100% - 80px);
    display: block;
    font-size: 16px;
    color: #A1A1A1;
}

.edit-profile-private-photo {
    margin-top: 30px;
}

.edit-profile-private-photo-title {
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    color: #000;
    font-size: 20px;
    display: block;
}

.edit-profile-private-photo-sub-title {
    display: block;
    font-size: 16px;
    color: #A1A1A1;
    margin-top: 10px;
}

.edit-profile-private-photo-elements {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    position: relative;
    min-height: 320px;
}

.edit-profile-private-photo-label {
    position: absolute;
    bottom: 4px;
    left: 0;
    width: 100%;
    height: 25px;
    border-radius: 0 0 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background-color: rgba(0,0,0,.5);
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
}

.edit-profile-private-photo-adult {
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 500;
    padding: 5px 10px;
    height: 22px;
    border-radius: 10px 0 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background-color: rgba(255,0,0,.5);
    color: #FFFFFF;
    font-size: 14px;
}

.edit-profile-private-photo-element {
    width: 46%;
    margin-right: 3%;
    cursor: pointer;
    margin-top: 10px;
    display: block;
    position: relative;
}

.edit-profile-private-photo-element-close {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 4px;
    top: 4px;
}

.edit-profile-private-photo-element-close:hover {
    opacity: .6;
}

.edit-profile-private-photo-element-close-icon {
    width: 30px;
    height: 30px;
}

.edit-profile-private-photo-element-image {
    width: 100%;
    border-radius: 10px;
}

.edit-profile-username-area {
    margin-top: 20px;
}

.edit-profile-username-area-title {
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    color: #000;
    font-size: 20px;
    display: block;
}

.edit-profile-username-area input {
    width: 100%;
    height: 30px;
    border: 1px solid #2C3149;
    border-radius: 4px;
    background-color: #EEEEEE;
    outline-color: rgba(0, 80, 219, 1);
    margin-top: 15px;
    font-family: 'Source Sans Pro Regular', sans-serif;
    padding-left: 10px;
    font-size: 14px;
    padding-right: 10px;
    box-sizing: border-box;
}

.edit-profile-checkboxes-switcher {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.edit-profile-checkboxes-switcher-right {
    margin-left: auto;
    width: 80px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.edit-profile-checkboxes-switcher-label {
    width: calc(100% - 80px);
    display: block;
    font-size: 14px;
    line-height: 18px;
    color: #2D384F;
}

.edit-profile-hankies {
    display: flex;
    flex-wrap: wrap;
}

.edit-profile-private-photo-loader {
    position: relative;
    height: 400px;
}

.edit-profile-hankies-element {
    position: relative;
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    cursor: pointer;
    opacity: 1;
}

.edit-profile-hankies-element:hover {
    opacity: .7;
}


.edit-profile-hankies-element-icon {
    display: block;
    width: 80%;
}

.edit-profile-hankies-element-glow {
    display: block;
    width: 100%;
    position: absolute;
    top: -10%;
    left: 0;
    opacity: .4;
}

.edit-profile-delete-link {
    margin-top: 30px;
    display: block;
    color: red;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    font-size: 18px;
    cursor: pointer;
}

.edit-profile-delete-link:hover {
    opacity: .7;
}


@media only screen and (max-width: 890px) {

    .edit-profile-inner {
        flex-direction: column;
    }

    .edit-profile-left, .edit-profile-right {
        width: 100%;
    }

    .edit-profile {
        margin-top: 20px;
    }

    .edit-profile-image-type-icon {
        left: 5px;
    }

    .edit-profile-image-area-plus {

    }

    .edit-profile-left {
        padding-left: 20px;
        padding-right: 20px;
    }

    .edit-profile-right {
        padding-left: 20px;
        padding-right: 20px;
    }

    .edit-profile-image-area {
        width: 230px;
        margin: auto;
        height: 230px;
        padding-top: 15px;
    }

    .edit-profile-image-area-circle {
        width: 200px;
        height: 200px;
        border-radius: 200px;
    }

    .edit-profile-image-type-icon {
        width: 50px;
        height: 50px;
        top: 85px;
        left: -10px;
    }

    .edit-profile-image-type-icon-image {
        width: 50px;
        height: 50px;
    }

    .edit-profile-image-area-plus {
        right: 14px;
    }

}
