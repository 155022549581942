.profiles-page {
    width: 100%;
    min-height: calc(100vh - 86px - 150px);
}

.profiles-page-title {
    font-size: 48px;
    margin: 0;
    text-align: center;
    padding: 0;
    display: block;
    height: 55px;
    color: #ffffff;
    font-family: 'Source Sans Pro Black', sans-serif;
}

.profiles-page-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
}

.profiles-page-top-back {
    color: #FFFFFF;
    cursor: pointer;
    font-size: 30px;
    position: absolute;
    top: 0;
    left: 0;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
}

.profiles-page-inner {
    width: 100%;
}

.profiles-page-text {
    display: block;
    text-align: center;
    width: 540px;
    font-size: 20px;
    color: #ffffff;
    margin: 30px auto 0;
}

.profiles-empty {
    width: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
}

.profile-empty {
    width: 32%;
    box-sizing: border-box;
    position: relative;
}

.profile-empty-block {
    display: flex;
    flex-direction: row;
    height: 100px;
    width: 100%;
    cursor: pointer;
}

.profile-empty-block:hover {
    opacity: .7;
}

.profile-empty:nth-child(2) {
    margin-left: 2%;
    margin-right: 2%;
}

.profile-empty-inner {
    width: calc(100% - 80px);
    height: 100px;
    border-radius: 8px;
    padding-left: 60px;
    position: relative;
    box-shadow: 0 0 7px rgba(0,0,0,.6);
}

.profile-empty-inner.friend {
    background-color: #1A4FD6;
}

.profile-empty-inner.flirt {
    background-color: #232D95;
}

.profile-empty-inner.fun {
    background-color: #202020;
}


.profile-empty-inner-left {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100px;
}

.profile-empty-inner-right {
    border-left: 1px solid #ffffff;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-empty-text-image {
    width: 10px;
    display: block;
}

.profile-empty-left {
    width: 40px;
    height: 100px;
}

.profile-empty-name {
    font-family: 'Source Sans Pro Bold', sans-serif;
    font-size: 16px;
    color: #ffffff;
}

.profile-empty-circle {
    width: 80px;
    height: 80px;
    border-radius: 80px;
    position: absolute;
    top: 10px;
    left: 0;
    z-index: 2;
}

.profile-empty-circle-image {
    width: 80px;
    height: 80px;
    border-radius: 80px;
}

.profile-empty-circle-plus {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 20px;
    height: 20px;
    border-radius: 20px;
}

.profile-empty-text {
    display: block;
    margin-top: 40px;
    text-align: center;
    color: #ffffff;
    font-size: 18px;
}

.profile-empty-text b {
    font-family: 'Source Sans Pro Bold', sans-serif;
}


.profile {
    width: 100%;
    margin-top: 30px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    height: 140px;
    cursor: pointer;
}

.profile-block:hover {
    opacity: .7;
}

.profile-inner {
    width: calc(100% - 80px);
    height: 140px;
    border-radius: 8px;
    padding-left: 100px;
    position: relative;
    box-shadow: 0 0 7px rgba(0,0,0,.6);
}

.profile-inner.friend {
    background-color: #1A4FD6;
}

.profile-inner.flirt {
    background-color: #232D95;
}

.profile-inner.fun {
    background-color: #202020;
}


.profile-inner-left {
    width: calc(100% - 50px);
    display: flex;
    align-items: center;
    height: 140px;
    padding-right: 10px;
    box-sizing: border-box;
}

.profile-inner-right {
    border-left: 1px solid #ffffff;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
}

.profile-inner-right:hover {
    opacity: .7;
}

.profile-text-image {
    width: 13px;
    display: block;
}

.profile-middle {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.profile-icons {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
}

.profile-edit-link {
    font-size: 16px;
    font-family: 'Source Sans Pro Light', sans-serif;
    margin-top: 16px;
    color: #ffffff;
    cursor: pointer;
}

.profile-edit-link:hover {
    opacity: .7;
}

.profile-icon {
    display: flex;
    margin-right: 20px;
    position: relative;
    cursor: pointer;
}

.profile-icon:hover {
    opacity: .7;
}

.profile-icon-badge {
    width: 18px;
    position: absolute;
    left: 14px;
    top: -9px;
    height: 18px;
    border-radius: 18px;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    color: #FFFFFF;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
}

.profile-icon-image {
    width: 20px;
    display: block;
}

.profile-active-icon {
    width: 10px;
    display: block;
    margin-bottom: 10px;
}

.profile-left {
    width: 65px;
    height: 140px;
}

.profile-name {
    font-family: 'Source Sans Pro Bold', sans-serif;
    font-size: 25px;
    color: #ffffff;
}

.profile-circle {
    width: 120px;
    height: 120px;
    border-radius: 120px;
    position: absolute;
    top: 9px;
    left: 0;
    z-index: 2;
    border: 1px solid #ffffff;
}

.profile-circle-image {
    width: 120px;
    height: 120px;
    border-radius: 120px;
}

.profile-circle-plus {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 30px;
    height: 30px;
    border-radius: 30px;
}

.profile-circle-icon {
    width: 50px;
    height: 50px;
    position: absolute;
    left: -25px;
    top: 35px;
    border-radius: 50px;
}

.profile-circle-icon-image {
    width: 50px;
    height: 50px;
    border-radius: 50px;
}

.profile-circle-icon.friend {
    background-color: #1A4FD6;
}

.profile-circle-icon.flirt {
    background-color: #232D95;
}

.profile-circle-icon.fun {
    background-color: #202020;
}

.profiles {
    width: 700px;
    margin: 40px auto;
}

.profile-page-settings-link {
    margin: 50px auto 20px;
    display: block;
    background-color: #133FAF;
    border-radius: 10px;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    cursor: pointer;
    color: #FFFFFF;
    text-decoration: none;
    padding: 10px 12px;
    max-width: 180px;
    font-size: 24px;
    text-align: center;
}

.profiles-page-logout-link {
    margin: 20px auto;
    color: #FFFFFF;
    font-size: 18px;
    text-align: center;
    display: block;
    cursor: pointer;
}

.profiles-page-logout-link:hover, .profile-page-settings-link:hover {
    opacity: .7;
}

@media only screen and (max-width: 1090px) {
    .profiles {
        width: 100%;
    }
}

@media only screen and (max-width: 790px) {

    .profiles-page {
        padding-left: 0;
        padding-right: 0;
        box-sizing: border-box;
    }

    .profiles-page-sub {
        padding-left: 25px;
        padding-right: 25px;
    }

    .profile-name {
        font-size: 17px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        display: block;
    }

    .profiles-page-title {
        font-size: 24px;
        height: auto;
    }

    .profiles {
        width: 100%;
        margin-left: 25px;
        margin-top: 20px;
    }

    .profile-circle {
        width: 80px;
        height: 80px;
        left: -40px;
        top: 30px;
    }

    .profile-circle-image {
        width: 80px;
        height: 80px;
    }

    .profile-circle-icon {
        width: 30px;
        height: 30px;
        left: -15px;
        top: 27px;
    }

    .profile-circle-icon-image {
        width: 30px;
        height: 30px;
    }

    .profile-inner {
        width: 100%;
        padding-left: 60px;
        box-sizing: border-box;
    }

    .profile-left {
        width: 0;
    }

    .profiles-page-text {
        width: 100%;
        font-size: 14px;
    }

    .profiles-empty {
        flex-direction: column;
        margin-top: 0;
    }

    .profile-empty {
        width: 100%;
        margin-top: 40px;
    }

    .profile-empty:nth-child(2) {
        margin-left: 0;
        margin-right: 0;
    }

    .profile-empty-text {
        margin-top: 20px;
    }

}
