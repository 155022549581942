.search {
    width: 100%;
    background-color: #ffffff;
    margin-top: 12px;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    position: relative;
    min-height: 500px;
    box-sizing: border-box;
}

.search-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.search-left {
    width: 50%;
    box-sizing: border-box;
    border-right: 1px solid #e7e7e7;
}

.search-right {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    flex-direction: column;
}

.search-right-text {
    display: block;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
    text-align: center;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
}

.search-right-text:last-child {
    margin-bottom: 0;
}

.search-tab-top {
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    flex-direction: row;
    padding: 12px;
    box-sizing: border-box;
    cursor: pointer;
    align-items: center;
    outline: none;
}

.search-tab-top-label {
    outline: none;
}

.search-tab-top.active {
    font-family: 'Source Sans Pro SemiBold', sans-serif;
}

.search-tab-top-icon {
    margin-left: auto;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    font-size: 20px;
}

.search-tab-content {
    padding: 12px;
    box-sizing: border-box;
    border-bottom: 1px solid #e7e7e7;
}

.search-tab-checkboxes {
    flex-wrap: wrap;
    display: flex;
}

.search-submit {
    background-color: #005BFA;
    border: 1px solid #005BFA;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 16px;
    display: flex;
    margin: 20px auto;
    border-radius: 10px;
    width: 120px;
    padding: 13px 17px;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    cursor: pointer;
}

.search-submit:hover {
    opacity: .7;
}

.search-tab-input {
    width: 100%;
    height: 40px;
    border: 1px solid #2C3149;
    border-radius: 4px;
    background-color: #EEEEEE;
    outline-color: rgba(0, 80, 219, 1);
    font-family: 'Source Sans Pro Regular', sans-serif;
    padding-left: 10px;
    font-size: 15px;
    padding-right: 10px;
    box-sizing: border-box;
}

@media only screen and (max-width: 790px) {

    .search-row {
        flex-direction: column-reverse;
    }

    .search-left {
        border-left: 0;
        width: 100%;
    }

    .search-right {
        width: 100%;
        border-bottom: 1px solid #e7e7e7;
    }

    .search-right-text {
        font-size: 16px;
        margin-bottom: 15px;
    }


}
