@font-face {
  font-family: 'Source Sans Pro Regular';
  src: url('../assets/fonts/SourceSansPro-Regular.ttf') format('truetype')
}

@font-face {
  font-family: 'Source Sans Pro SemiBold';
  src: url('../assets/fonts/SourceSansPro-SemiBold.ttf') format('truetype')
}

@font-face {
  font-family: 'Source Sans Pro Black';
  src: url('../assets/fonts/SourceSansPro-Black.ttf') format('truetype')
}

@font-face {
  font-family: 'Source Sans Pro Light';
  src: url('../assets/fonts/SourceSansPro-Light.ttf') format('truetype')
}

@font-face {
  font-family: 'Source Sans Pro Bold';
  src: url('../assets/fonts/SourceSansPro-Bold.ttf') format('truetype')
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro Regular', sans-serif;
}

.container {
  max-width: 1280px;
  margin: auto;
  height: 100%;
  position: relative;
}

.container.with-flex-row {
  display: flex;
  flex-direction: row;
}

.content-area {
  background-color: #1A4FD6;
  width: 100%;
  min-height: calc(100vh - 86px - 150px);
  padding-top: 30px;
}

.content-area-container {
  max-width: 1280px;
  margin: auto;
  padding-bottom: 30px;
}

.content-area-container:after {
  clear: both;
  display: block;
  content: '';
}


.content-area-inner {
  width: calc(100% - 250px);
  position: relative;
  margin-left: 30px;
  float: left;
}

.content-area-top-left {
  position: absolute;
  left: 0;
  top: 20px;
  cursor: pointer;
}

.content-area-top-left:hover {
  opacity: .7;
}

.content-area-top-left-icon {
  display: block;
  height: 41px;
}

.content-area-title {
  text-align: center;
  margin: 0;
  padding: 0;
  color: #ffffff;
  height: 56px;
  font-size: 40px;
  font-family: 'Source Sans Pro Black', sans-serif;
}

.content-area-top {
  display: flex;
  height: 81px;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  position: relative;
}

.aside {
  width: 220px;
  position: sticky;
  top: 30px;
  float: left;
  left: 0;
}

.menu {
  background-color: #133FAF;
  height: 500px;
  width: 100%;
  padding-top: 5px;
  border-radius: 7px;
}

.menu-element {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  height: 35px;
  padding-left: 15px;
  margin-top: 10px;
  position: relative;
}

.menu-element:hover {
  opacity: .7;
}

.menu-element-icon {
  width: 18px;
  display: block;
  height: 18px;
}

.menu-element-label {
  color: #ffffff;
  font-size: 22px;
  font-family: 'Source Sans Pro Light', sans-serif;
  margin-left: 15px;
}

.menu-element-badge {
  width: 18px;
  position: absolute;
  left: 24px;
  top: -2px;
  height: 18px;
  border-radius: 18px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
  color: #FFFFFF;
  font-family: 'Source Sans Pro SemiBold', sans-serif;
}

.menu-element.active .menu-element-label {
  font-family: 'Source Sans Pro SemiBold', sans-serif;
}

.menu-element.active:hover {
  opacity: 1;
}

.icons {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  height: 81px;
}

.icon {
  width: 60px;
  display: block;
  opacity: .4;
  border-radius: 60px;
  cursor: pointer;
  height: 60px;
}

.icon.friend {
  background-color: #1A4FD6;
}

.icon.flirt {
  background-color: #232D95;
}

.icon.fun {
  background-color: #202020;
}

.icon:nth-child(2) {
  margin-left: 20px;
  margin-right: 20px;
}

.icon img {
  width: 60px;
  height: 60px;
  border-radius: 60px;
}

.icon-label {
  display: block;
  text-align: center;
  font-size: 13px;
  margin-top: 2px;
  font-family: 'Source Sans Pro Bold', sans-serif;
  color: #ffffff;
}

.icon.active,
.icon:hover {
  opacity: 1;
}

.loading-page {
  width: 100%;
  min-height: calc(100vh - 86px - 150px);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(0, 0, 0, .1);
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.gm-style-mtc {
  display: none;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.lds-ripple,
.lds-ripple div {
  box-sizing: border-box;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #1A4FD6;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 1;
  }

  100% {
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    opacity: 0;
  }
}

@media only screen and (max-width: 1290px) {

  .container {
    max-width: 1180px;
  }

  .content-area-container {
    max-width: 1180px;
  }

}

@media only screen and (max-width: 1190px) {

  .container {
    max-width: 1080px;
  }

  .content-area-container {
    max-width: 1080px;
  }

}

@media only screen and (max-width: 1090px) {

  .container {
    max-width: 980px;
  }

  .content-area-container {
    max-width: 980px;
  }

  .content-area-inner {
    width: calc(100% - 250px);
    margin-right: 0;
  }

  .aside-advertising {
    display: none;
  }

}

@media only screen and (max-width: 990px) {

  .container {
    max-width: 880px;
  }

  .content-area-container {
    max-width: 880px;
  }

}

@media only screen and (max-width: 890px) {

  .container {
    max-width: 780px;
  }

  .content-area-container {
    max-width: 780px;
  }

}

@media only screen and (max-width: 790px) {

  .container {
    max-width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    box-sizing: border-box;
  }

  .content-area-container {
    max-width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    box-sizing: border-box;
  }

  .content-area {
    min-height: calc(100vh - 50px - 70px);
  }

  .aside {
    display: none;
  }

  .content-area-inner {
    width: 100%;
    margin-left: 0;
  }

}

.header-bar {
  display: none;
}

.mobile-menu {
  display: none;
}

@media only screen and (max-width: 790px) {

  .header-bar {
    display: block;
    height: 24px;
    width: 24px;
    margin-left: auto;
    margin-top: 13px;
  }

  .header-bar-icon {
    width: 24px;
    height: 24px;
  }

  .mobile-menu {
    padding: 20px 14px;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    overflow-y: auto;
    background-color: #133FAF;
    height: 100%;
    box-sizing: border-box;
  }

  .mobile-menu-fade {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
  }

  .mobile-menu .icon {
    width: 40px;
    height: 40px;
  }

  .mobile-menu .icons {
    height: 50px;
  }

  .mobile-menu .icon img {
    width: 40px;
    height: 40px;
  }

  .mobile-menu .menu-element {
    padding-left: 0;
    margin-top: 5px;
  }

  .mobile-menu .menu-element-label {
    font-size: 18px;
  }

  .mobile-menu .menu {
    margin-top: 30px;
  }

  .content-area-inner .container {
    padding-left: 0;
    padding-right: 0;
  }

  .content-area-container {
    padding-left: 12px;
    padding-right: 12px;
  }

  .content-area {
    padding-top: 15px;
  }

  .content-area-title {
    font-size: 24px;
    height: auto;
  }

  .content-area-top {
    height: 30px;
  }

  .content-area-top-left {
    position: absolute;
    left: 0;
    top: 4px;
    cursor: pointer;
  }

  .content-area-top-left:hover {
    opacity: .7;
  }

  .content-area-top-left-icon {
    display: block;
    height: 26px;
  }

}

.input-eye {
  position: absolute;
  top: 30px;
  right: 12px;
  cursor: pointer;
}

.input-eye img {
  width: 20px;
}