.support {
    width: 100%;
    background-color: #ffffff;
    margin-top: 12px;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    position: relative;
    min-height: 500px;
    box-sizing: border-box;
}

.support-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.support-left {
    width: 50%;
    box-sizing: border-box;
    border-right: 1px solid #e7e7e7;
    padding: 20px;
}

.support-right {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.support-label {
    color: #000;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    font-size: 15px;
    margin-top: 30px;
    display: block;
}

.support-label:first-child {
    margin-top: 0;
}

.support-large-text, .support-radios {
    margin-top: 10px;
}

.support-large-text-input {
    resize: none;
    width: 100%;
    font-size: 15px;
    height: 200px;
    box-sizing: border-box;
    padding: 15px;
    border-radius: 10px;
    background-color: #EEEEEE;
    color: #000;
}

.support-submit {
    background-color: #005BFA;
    border: 1px solid #005BFA;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 20px;
    margin: 40px auto 0;
    border-radius: 8px;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
    cursor: pointer;
}

.support-icon {
    max-width: 50%;
    display: block;
}


@media only screen and (max-width: 790px) {

    .support {
        min-height: auto;
        flex-direction: column;
    }

    .support-row {
        flex-direction: column-reverse;
    }

    .support-left {
        border-left: 0;
        width: 100%;
    }

    .support-right {
       display: none;
    }


}
