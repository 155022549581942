.stats {
    width: 100%;
    background-color: #ffffff;
    margin-top: 12px;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    position: relative;
    min-height: 500px;
    box-sizing: border-box;
}

.stats-elements {
    display: flex;
    flex-wrap: wrap;
}

.stats-element {
    width: 50%;
    padding: 30px;
    box-sizing: border-box;
}

.stats-element-label {
    display: block;
    font-size: 17px;
    font-family: 'Source Sans Pro Bold', sans-serif;
    color: #000000;
}

.stats-element-values {
    margin-top: 15px;
}

.stats-element-value {
    display: flex;
    flex-direction: row;
    margin-top: 7px;
    color: #4A4A4A;
    font-size: 19px;
    font-family: 'Source Sans Pro Regular', sans-serif;
}

.stats-element-value b {
    width: 30px;
    color: #1D4C83;
    display: inline-block;
    font-family: 'Source Sans Pro SemiBold', sans-serif;
}

@media only screen and (max-width: 790px) {

    .stats-element {
        width: 100%;
    }

}
